import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';

document.addEventListener('DOMContentLoaded', () => {
  let sliders = [...document.querySelectorAll('[data-slider]')];
  sliders.forEach((sliderEl) => {
    // Init slider
    let slider = sliderEl.querySelector('[data-slider-slider]');
    if (slider) {
      // only init swiper if there's more than 1 slide
      if ([...slider.querySelectorAll('.swiper-slide')].length > 1) {
        let swiper = new Swiper(slider, {
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 5000,
          },
          grabCursor: true,
          parallax: true,

          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazy: {
            loadPrevNext: false,
            loadOnTransitionStart: true,
          },

          pagination: {
            el: '[data-slider-pagination]',
            clickable: true,
          },

          on: {
            init: function() {
              slider.classList.add('swiper-initialized');
            },

            lazyImageReady(slideEl, imageEl) {
              objectFitImages(imageEl);
            },
          },
        });
      }
    }
  });
});
