import SmoothScroll from 'smooth-scroll';

document.addEventListener('DOMContentLoaded', (event) => {
  new SmoothScroll('[data-smooth-scroll]', {
    speed: 1000,
  });
});

document.addEventListener('scrollStart', (e) => {
  e.detail.toggle.blur();
});
