export default class Share {
  constructor() {
    document.addEventListener('DOMContentLoaded', (event) => {
      this.buttons = document.querySelectorAll('[data-share]');
      if (this.buttons.length) {
        this.initHandlers();
      }
    });
  }

  initHandlers() {
    // add links to share buttons
    [...this.buttons].forEach((button) => {
      let urlToShare = button.getAttribute('href');
      if (!urlToShare || urlToShare === '#') {
        urlToShare = location.href;
      }
      button.setAttribute('href', this._getShareUrl(button.getAttribute('data-share'), urlToShare));
      button.setAttribute('target', '_blank');
    });
  }

  /**
   * getShareUrl - Returns the URL that can be used to share the url that is passed to the function on social media platform `type`. Default is facebook.
   *
   * @param  {string} type Can be one of 'facebook', 'twitter' or 'google'. Default is 'facebook'.
   * @param  {string} url  The URL to be shared
   * @return {string}      The sharable URL
   */
  _getShareUrl(type, url) {
    if (type === 'twitter') {
      return `https://twitter.com/home?status=${encodeURIComponent(url)}`;
    } else if (type === 'google') {
      return `https://plus.google.com/share?url=${encodeURIComponent(url)}`;
    } else if (type === 'pinterest') {
      return `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`;
    } else if (type === 'linkedin') {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
    } else if (type === 'xing') {
      return `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(url)}`;
    } else if (type === 'mail') {
      return `mailto:?body=${encodeURIComponent(url)}`;
    } else {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    }
  }
}
