export default class Map {
  constructor() {
    document.addEventListener('DOMContentLoaded', (event) => {
      this.mapEl = document.querySelector('[data-map]');
      if (this.mapEl) {
        this.map = null;

        let iconBaseWidth = 50;
        this.settings = {
          center: [
            this.mapEl.getAttribute('data-map-latitude'),
            this.mapEl.getAttribute('data-map-longitude'),
          ],
          zoom: parseInt(this.mapEl.getAttribute('data-map-zoom')),
          scrollWheelZoom: false,
          icon: {
            sizeX: iconBaseWidth * 50 / 61,
            sizeY: iconBaseWidth,
          },
          markers: [
            {
              path: this.mapEl.getAttribute('data-map-pin-path'),
              lat: this.mapEl.getAttribute('data-map-latitude'),
              long: this.mapEl.getAttribute('data-map-longitude'),
              popup: this.mapEl.getAttribute('data-map-popup'),
            },
          ],
        };

        // Check for Leaflet existence
        if (typeof window.L === 'undefined' || typeof window.L.map === 'undefined') {
          // Create a new script element
          let script = document.createElement('script');
          script.src = 'https://unpkg.com/leaflet@1.3.4/dist/leaflet.js';
          script.integrity = 'sha512-nMMmRyTVoLYqjP9hrbed9S+FzjZHW5gY1TWCHA5ckwXZBadntCNs8kEqAWdrb9O7rxbCaA4lKTIWjDXZxflOcA==';
          script.crossOrigin = '';

          // Create a new stylesheet element
          let stylesheet = document.createElement('link');
          stylesheet.type = 'text/css';
          stylesheet.rel = 'stylesheet';
          stylesheet.href = 'https://unpkg.com/leaflet@1.3.4/dist/leaflet.css';
          stylesheet.integrity = 'sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA==';
          stylesheet.crossOrigin = '';

          // Load the map when the script has loaded
          script.onload = this.initMap.bind(this);

          // Add script and stylesheet to the DOM
          document.body.appendChild(script);
          document.head.appendChild(stylesheet);
        } else {
          this.initMap();
        }
      }
    });
  }

  initMap() {
    // Create new Leaflet Map instance.
    this.map = window.L.map(this.mapEl, this.settings);

    // Add tile layer
    window.L.tileLayer('https://c.tile.openstreetmap.de/{z}/{x}/{y}.png', {
      attribution: 'Map data © <a href="http://openstreetmap.de">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.settings.markers.forEach((marker) => {
      // Add map pin
      let mapIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });

      // Set a new marker
      let markerLeaflet = window.L.marker(
        [
          marker.lat,
          marker.long,
        ],
        {
          icon: mapIcon,
        }
      ).addTo(this.map);

      // Popup (optional)
      if (marker.popup) {
        markerLeaflet.bindPopup(marker.popup, {
          className: 'c-map-popup',
        });
      }
    });
  }
}
