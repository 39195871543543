import throttle from 'lodash.throttle';

// Card tile animation
document.addEventListener('DOMContentLoaded', () => {
  let cardTiles = [...document.querySelectorAll('[data-card-tile]')];
  cardTiles.forEach((card) => {
    // instantly check text height
    checkTextHeight(card);

    // check text height on resize
    window.addEventListener('resize', throttle(checkTextHeight.bind(this, card)));

    // check text height on fonts loaded event
    document.addEventListener('fonts-sans-loaded', checkTextHeight.bind(this, card));
  });
});

function checkTextHeight(card) {
  const hiddenEl = card.querySelector('[data-card-tile-hidden]');
  card.style.setProperty('--hide-distance', hiddenEl.offsetHeight);
}
