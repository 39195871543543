import 'cookieconsent';

window.addEventListener('load', () => {
  let initialiser = document.querySelector('[data-cookieconsent-initialiser]');

  if (initialiser) {
    let message = initialiser.dataset.cookieconsentInitialiserMessage || '';
    let dismiss = initialiser.dataset.cookieconsentInitialiserDismiss || '';
    let link = initialiser.dataset.cookieconsentInitialiserLink || '';
    let href = initialiser.dataset.cookieconsentInitialiserHref || '';

    window.cookieconsent.initialise({
      'palette': {
        'popup': {
          'background': '#ffffff',
          'text': '#000'
        },
        'button': {
          'background': 'transparent',
          'text': '#000',
          'border': '#000'
        }
      },
      'position': 'bottom-left',
      'content': {
        'message': message,
        'dismiss': dismiss,
        'link': link,
        'href': href,
      },
    });
  }
});
