import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';

document.addEventListener('DOMContentLoaded', () => {
  let galleries = [...document.querySelectorAll('[data-gallery]')];
  galleries.forEach((gallery) => {
    // Init slider
    let slider = gallery.querySelector('[data-gallery-slider]');
    if (slider) {
      // only init swiper if there's more than 1 slide
      if ([...slider.querySelectorAll('.swiper-slide')].length > 1) {
        let swiper = new Swiper(slider, {
          loop: true,
          speed: 1000,
          grabCursor: true,

          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazy: {
            loadPrevNext: true,
            loadOnTransitionStart: true,
          },

          pagination: {
            el: '[data-gallery-slider-pagination]',
            clickable: true,
          },

          navigation: {
            prevEl: '[data-gallery-slider-prev]',
            nextEl: '[data-gallery-slider-next]',
          },

          on: {
            init: function() {
              slider.classList.add('swiper-initialized');
              alignControls(this);
            },

            paginationRender() {
              alignControls(this);
            },

            lazyImageReady(slideEl, imageEl) {
              objectFitImages(imageEl);
              alignControls(this, imageEl);
            },

            slideChangeTransitionEnd() {
              alignControls(this);
            },

            resize() {
              alignControls(this);
            },
          },
        });

        function alignControls(swiper, image) {
          if (swiper) {
            image = image || swiper.slides[swiper.activeIndex].querySelector('img');
            let imagePixelHeight = image.offsetHeight;

            [
              {
                el: swiper.navigation.nextEl,
                offset: imagePixelHeight / 2,
              },
              {
                el: swiper.navigation.prevEl,
                offset: imagePixelHeight / 2,
              },
              {
                el: swiper.pagination.el,
                offset: imagePixelHeight,
              },
            ].forEach((controlEl) => {
              if (controlEl.el) {
                controlEl.el.style.top = `${controlEl.offset}px`;
              }
            });
          }
        }
      }
    }
  });
});
