// Reveal animations
// scrollreaveal should be a window variable, set in the <head> script

if (typeof window.sr !== 'undefined') {
  if (!document.body.classList.contains('no-reveal')) {
    [...document.querySelectorAll('[data-reveal]')].forEach((el) => {
      window.sr.reveal(el, {
        delay: 40,
        duration: el.dataset.revealDuration || 1500,
        distance: el.dataset.revealDistance || '20px',
        easing: el.dataset.revealEaseIn !== undefined ? 'cubic-bezier(0, 0, .2, 1)' : 'cubic-bezier(0.5, 0, 0, 1)',
        interval: 100,
        viewFactor: 0.1,
        scale: 1.04,
        reset: el.dataset.revealNoReset === undefined,
        origin: el.dataset.revealOrigin || 'bottom',
      });
    })
  }
}
