import 'whatwg-fetch';
import Loader from './loader';

export default class Contactform {
  constructor() {
    this.loader = new Loader();
    document.addEventListener('DOMContentLoaded', (event) => {
      this.form = document.querySelector('[data-contactform]');
      if (this.form) {
        this.initSubmit();
      }
    });
  }

  initSubmit() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.postFormData();
    });
  }

  postFormData() {
    this.loader.open();
    this.setErrorState(false);
    this.resetValidationStates();

    fetch('/wheelform/message/send', {
      method: 'POST',
      body: new FormData(this.form),
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(this.responseHandler.bind(this))
    .then(this.jsonHandler.bind(this))
    .catch((error) => {
      this.loader.close();
      let errorMessage = 'Fehler beim Absenden des Formulars: ' + error.message;
      console.error(errorMessage);
      this.setErrorState(true, errorMessage);
    });
  }

  responseHandler(response) {
    this.loader.close()

    if (response.ok) {
      return response.json();
    }

    throw new Error('Network response was not ok.');
  }

  jsonHandler(json) {
    if (json.success) {
      this.setSuccessState();
    } else {
      this.setValidationStates(json.errors);
    }
  }

  setErrorState(hasError, message) {
    if (message) {
      let errorMessageContainer = this.form.querySelector('[data-contactform-error-message]');
      errorMessageContainer.innerHTML = message;
    }

    hasError
    ? this.form.classList.add('has-error')
    : this.form.classList.remove('has-error');
  }

  setSuccessState() {
    this.form.classList.add('has-succeeded');
  }

  setValidationStates(errors) {
    Object.keys(errors).forEach((fieldName) => {
      let element = this.form.elements[fieldName] || this.form.elements[`${fieldName}[]`];
      element && element.inputClass.setErrorState(true, errors[fieldName]);
    });
  }

  resetValidationStates() {
    [...this.form.elements].forEach((element) => {
      if (typeof element.inputClass !== 'undefined') {
        element.inputClass.setErrorState(false);
      }
    });
  }
}
